<template>
  <div>
    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" v-if="loading">
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4">
        <div style="height: 130px" class="mx-1">
          <stat-card :loading="loading" :statistic="{
            title: 'Títulos',
            quantity: counters.titles.total,
            icon: 'shield-alt',
            change: counters.titles.actives,
            changeVar: 'títulos activos'
          }" bgColor="bg-red-1100" />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card :loading="loading" :statistic="{
            title: 'Jugadores',
            quantity: counters.players.total,
            icon: 'users',
            change: counters.players.actives,
            changeVar: 'jugadores activos'
          }" bgColor="bg-green-1100" />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card :loading="loading" :statistic="{
            title: 'Entrenadores',
            quantity: counters.coaches.total,
            icon: 'chalkboard-teacher',
            change: counters.coaches.actives,
            changeVar: 'entrenadores activos'
          }" bgColor="bg-orange-1100" />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card :loading="loading" :statistic="{
            title: 'Categorías',
            quantity: counters.categories.total,
            icon: 'chess',
            change: counters.categories.actives,
            changeVar: 'categorías activas'
          }" bgColor="bg-yellow-1100" />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-end gap-2 my-4">
        <t-button variant="warning" class="my-2 md:my-auto h-fit py-2" @click="linksModal = true">
          Enlaces de inscripción
        </t-button>

        <t-button variant="warning" class="my-2 md:my-auto h-fit py-2" @click="
          $router.push('/academias/' + $route.params.id + '/categorias/crear')
        ">
          Añadir categoría
        </t-button>
      </div>

      <div class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 gap-2 gap-y-4 mb-4">
        <div v-for="item of categories" :key="item.id" class="mx-1">
          <div class="w-full h-full rounded-lg px-4 py-2 bg-white shadow-xl bgTeam" :style="{
            'background-image': item.url_picture
              ? 'url(' + item.url_picture + ')'
              : 'url(https://storage.googleapis.com/liga-internacional/20220630_120520_oKhqNC_grama.jpg)'
          }">
            <div class="flex flex-col h-full content-between">
              <br /><br /><br />
              <div class="w-full items-center">
                <div class="w-full rounded-lg blurbox">
                  <p class="my-0 py-0 text-center font-bold uppercase" style="color: #000000; font-size: 36px">
                    {{ item.category ? item.category.name : "No definida" }}
                  </p>
                  <p class="my-0 pt-0 pb-1 text-center" style="font-size: 20px">
                    {{
                    item.subcategory ? item.subcategory.name : "No definida"
                    }}
                  </p>
                </div>

                <div class="w-full grid grid-cols-2 md:grid-cols-1">
                  <t-button variant="error" class="mt-1 text-center w-full py-2" @click="
                    $router.push(
                      '/academias/' +
                        $route.params.id +
                        '/categorias/' +
                        item.id +
                        '/jugadores'
                    )
                  ">
                    Ver plantilla
                  </t-button>
                  <t-button variant="error" class="mt-1 text-center w-full py-2" @click="
                    $router.push(
                      '/academias/' +
                        $route.params.id +
                        '/categorias/' +
                        item.id +
                        '/titulos'
                    )
                  ">
                    Ver titulos
                  </t-button>
                </div>
                <div class="w-full grid grid-cols-2 md:grid-cols-1">
                  <t-button variant="warning" class="mt-1 text-center w-full py-2" @click="
                    $router.push(
                      '/academias/' +
                        $route.params.id +
                        '/categorias/' +
                        item.id +
                        '/editar'
                    )
                  ">
                    Editar
                  </t-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <t-modal header="Enlaces de inscripción" :value="linksModal" @closed="closeModal('linksModal')">
      <div class="p-3">
        <t-input-group class="w-full mt-3" label="Enlace para jugadores">
          <div class="flex justify-around gap-1">
            <t-input v-validate="'required'" data-vv-validate-on="blur" class="bg-transparent" name="value_a"
              id="value_a" placeholder="Enlace para jugadores" autocomplete="off"
              :value="`${baseURL}/registro_jugador?token=${academy_token}`" readonly type="text" ref="playersLink" />
            <t-button type="button" :variant="playersLinkCopied ? 'success' : 'outline-success'"
              @click="copyLink('playersLink')">
              {{ playersLinkCopied ? "Copiado" : "Copiar" }}
            </t-button>
          </div>
        </t-input-group>

        <t-input-group class="w-full mt-3" label="Enlace para entrenadores">
          <div class="flex justify-around gap-1">
            <t-input v-validate="'required'" data-vv-validate-on="blur" class="bg-transparent" name="value_a"
              id="value_a" placeholder="Enlace para entrenadores" autocomplete="off"
              :value="`${baseURL}/registro_entrenador?token=${academy_token}`" readonly type="text" ref="coachesLink" />
            <t-button type="button" :variant="coachesLinkCopied ? 'success' : 'outline-success'"
              @click="copyLink('coachesLink')">
              {{ coachesLinkCopied ? "Copiado" : "Copiar" }}
            </t-button>
          </div>
        </t-input-group>
      </div>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="outline-danger" @click="linksModal = false" class="mr-2">
            Cerrar
          </t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      counters: {
        titles: {},
        players: {},
        coaches: {},
        categories: {}
      },
      categories: [],
      linksModal: false,
      baseURL: window.location.host,
      playersLinkCopied: false,
      coachesLinkCopied: false,
      academy_token: ""
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    closeModal(modalName) {
      if (modalName === "linksModal") {
        this.playersLinkCopied = false
        this.coachesLinkCopied = false
      }
      this[modalName] = false
    },
    copyLink(refName) {
      let content = this.$refs[refName].value
      navigator.clipboard
        .writeText(content)
        .then(() => {
          this[`${refName}Copied`] = true
        })
        .catch((err) => {
          this[`${refName}Copied`] = false
          console.log("Something went wrong", err)
        })
    },
    getAcademyCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/academy/" + this.$route.params.id + "/counters",
          noPaginate: true
        })
        .then((response) => {
          this.counters = response.data.counters
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getAcademyCategories() {
      this.$store
        .dispatch("global/getItems", {
          route:
            "academy_category/all?order_key=id_category&order_value=asc&id_academy=" +
            this.$route.params.id,
          noPaginate: true
        })
        .then((response) => {
          this.categories = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.counters.categories.actives--
    },
    activate() {
      this.counters.categories.actives++
    },
    erase() {
      this.counters.categories.total--
      this.counters.categories.actives--
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/academy",
          id: this.$route.params.id
        })
        .then((response) => {
          this.academy_token = response.data.token
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getAcademyCounters()
    this.getAcademyCategories()
    this.getItem()
  }
}
</script>

<style scoped>
.bgTeam {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blurbox {
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: opacity(0.7);
  backdrop-filter: opacity(0.7);
}
</style>
