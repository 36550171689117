<template>
  <div class="w-full h-full rounded-lg px-4 py-2 bg-white shadow-xl">
    <template v-if="loading">
      <div class="mx-auto my-auto">
        <t-loader extraClass="border-primary-200 w-8 h-8" />
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col h-full content-between">
        <div class="flex items-center justify-between h-5/6">
          <div class="flex flex-col">
            <p class="ml-1 text-sm text-gray-500 font-bold uppercase mb-4">
              {{ statistic.title }}
            </p>
            <p class="text-xl font-bold">
              {{ statistic.quantity }}
            </p>
          </div>
          <div
            class="rounded-full flex items-center justify-center"
            :class="bgColor"
            style="width: 50px; height: 50px"
          >
            <icon
              v-if="statistic.icon"
              :icon="statistic.icon"
              style="width: 26px; height: 26px"
              class="rounded-full text-white text-3xl"
            />
          </div>
        </div>
        <div class="justify-self-end flex items-center h-1/6">
          <icon
            :icon="
              statistic.change === 0
                ? 'minus'
                : statistic.change > 0
                ? 'arrow-up'
                : 'arrow-down'
            "
            class="text-md"
            :class="
              statistic.change === 0
                ? 'text-black'
                : statistic.change > 0
                ? 'text-green-1100'
                : 'text-red-1100'
            "
          />
          <p class="text-sm ml-2">
            <span
              :class="
                statistic.change === 0
                  ? 'text-black'
                  : statistic.change > 0
                  ? 'text-green-1100'
                  : 'text-red-1100'
              "
            >
              {{ statistic.change }}
            </span>
            {{ statistic.changeVar }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "card",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    statistic: {
      type: Object,
      default: () => {}
    },
    bgColor: {
      type: String,
      default: "bg-primary-700"
    }
  }
}
</script>
