var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full rounded-lg px-4 py-2 bg-white shadow-xl"},[(_vm.loading)?[_c('div',{staticClass:"mx-auto my-auto"},[_c('t-loader',{attrs:{"extraClass":"border-primary-200 w-8 h-8"}})],1)]:[_c('div',{staticClass:"flex flex-col h-full content-between"},[_c('div',{staticClass:"flex items-center justify-between h-5/6"},[_c('div',{staticClass:"flex flex-col"},[_c('p',{staticClass:"ml-1 text-sm text-gray-500 font-bold uppercase mb-4"},[_vm._v(" "+_vm._s(_vm.statistic.title)+" ")]),_c('p',{staticClass:"text-xl font-bold"},[_vm._v(" "+_vm._s(_vm.statistic.quantity)+" ")])]),_c('div',{staticClass:"rounded-full flex items-center justify-center",class:_vm.bgColor,staticStyle:{"width":"50px","height":"50px"}},[(_vm.statistic.icon)?_c('icon',{staticClass:"rounded-full text-white text-3xl",staticStyle:{"width":"26px","height":"26px"},attrs:{"icon":_vm.statistic.icon}}):_vm._e()],1)]),_c('div',{staticClass:"justify-self-end flex items-center h-1/6"},[_c('icon',{staticClass:"text-md",class:_vm.statistic.change === 0
              ? 'text-black'
              : _vm.statistic.change > 0
              ? 'text-green-1100'
              : 'text-red-1100',attrs:{"icon":_vm.statistic.change === 0
              ? 'minus'
              : _vm.statistic.change > 0
              ? 'arrow-up'
              : 'arrow-down'}}),_c('p',{staticClass:"text-sm ml-2"},[_c('span',{class:_vm.statistic.change === 0
                ? 'text-black'
                : _vm.statistic.change > 0
                ? 'text-green-1100'
                : 'text-red-1100'},[_vm._v(" "+_vm._s(_vm.statistic.change)+" ")]),_vm._v(" "+_vm._s(_vm.statistic.changeVar)+" ")])],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }