<template>
  <div class="flex relative">
    <t-loader
      v-if="isLoading"
      extraClass="absolute top-1/3 left-4 text-gray-500"
    />
    <icon
      v-if="!isLoading"
      icon="search"
      class="absolute top-1/3 left-4 text-gray-500"
    />
    <t-input
      :disabled="disabled"
      v-model="query"
      @keydown="(event) => handleKeyDown(event)"
      @keyup="debouncedInput"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      :placeholder="placeholder"
      class="bg-white rounded-md border-0 active:border-gray-500 focus:border-gray-500 placeholder-gray-700 shadow-lg pl-10"
    >
    </t-input>
  </div>
</template>
<script>
import debounce from "lodash.debounce"
export default {
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: "",
      isLoading: false
    }
  },
  methods: {
    handleKeyDown(event) {
      this.isLoading = true
      if (this.query === "") {
        this.$emit("loading", false)
      } else {
        this.$emit("loading", true)
      }
    },
    emitQueryChange() {
      this.$emit("changeQuery", this.query)
      this.isLoading = false
      this.$emit("loading", false)
    },
    debouncedInput() {}
  },
  created() {
    this.debouncedInput = debounce(this.emitQueryChange, 200)
  },
  watch: {
    query() {
      if (this.query === "") {
        this.loading = false
        this.$emit("loading", false)
      }
    }
  }
}
</script>
